import { useForm } from "react-hook-form";
import Button from "../../UI/Button.component";
import Heading from "../../UI/Heading.component";
import Input from "../../UI/Input.component";
import Modal from "../../UI/Modal.component";

type SendContractModalProps = {
  onClose: () => void;
  sendContract: (email: string) => void;
};
export const SendContractModal = ({
  onClose,
  sendContract,
}: SendContractModalProps) => {
  const { control, handleSubmit } = useForm<{ email: string }>();

  const onSubmit = ({ email }: any) => {
    sendContract(email);
  };
  return (
    <Modal
      show={true}
      onClose={onClose}
      colorClasses="bg-secondary-100 text-black"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading>Invia documento</Heading>

        <Input
          control={control}
          name="email"
          placeholder="Email"
          type="email"
          required
        />
        <Button className="mt-12 flex items-center gap-3">
          <img src="/icons/send.svg" alt="Invia" />{" "}
          <span className="h-6">Invia documento</span>
        </Button>
      </form>
    </Modal>
  );
};
