import {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from "react";
import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  useController,
} from "react-hook-form";
import { mergeClasses } from "../../utils/classes.utils";

type InputFileProps<T extends FieldValues> = {
  className?: string;
  control: Control<T, string>;
  name: Path<T>;
  rules?: RegisterOptions<T, Path<T>>;
  errors?: FieldErrors<T>;
} & InputHTMLAttributes<HTMLElement>;
export const InputFile = <T extends FieldValues>(props: InputFileProps<T>) => {
  const { field } = useController({ name: props.name, control: props.control });
  const [hasFile, setHasFile] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const onFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.[0]) {
        setHasFile(true);
        field.onChange(event.target.files[0]);
      } else {
        setHasFile(false);
      }
    },
    [field]
  );
  const containerDefaultClasses =
    "rounded-full  border border-dashed flex items-center justify-center h-40 relative z-20 bg-secondary-100 p-6 text-center";
  const containerValidityClasses = hasFile
    ? "border-success-500 text-success-500"
    : "border-secondary-500";
  const containerClasses = [containerDefaultClasses, containerValidityClasses];
  return (
    <div className="relative">
      <div
        onClick={() => inputRef.current?.click()}
        className={mergeClasses(containerClasses)}
      >
        <strong>
          {hasFile ? "Foto caricata con successo!" : props.placeholder}
        </strong>
      </div>
      <input
        {...props}
        ref={inputRef}
        type="file"
        className="-z-10 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        onChange={onFileChange}
      />
      {/* <p>{props.errors[props.name]?.message}</p> */}
    </div>
  );
};
