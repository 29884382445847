export type Contract = {
  id: string;
  name: string;
  emailVenditore?: string;
  email?: string;
  apartment: string;
  html: string;
  status: keyof typeof ContractStatusStrings;
  tesseraFronte?: string;
  documentoFronte?: string;
  documentoRetro?: string;
  tesseraFronteProponente?: string;
  documentoFronteProponente?: string;
  documentoRetroProponente?: string;
  ContractTypeId: keyof typeof ContractTypeStrings;
  contractPdf: string;
  contractPdfSigned?: string;
  createdAt: Date;
  updatedAt: Date;
  hasDocuments: boolean;
};

export type ContractDocumentForm = {
  documentoFronte: File;
  documentoRetro: File;
  tesseraFronte: File;
};
export type CreateContractPayload = {
  id: string;
  email?: string;
  apartment: string;
  name: string;
  html: string;
  ContractTypeId: string;
  template?: string;
  hasDocuments: boolean;
};

export const contractTypes = [
  {
    label: "Accordo mediazione",
    value: "MEDIAZIONE",
    icon: "mandato-affitto.svg",
  },
  {
    label: "Incarico esclusiva",
    value: "INCARICO_ESCLUSIVA",
    icon: "mandato-vendita.svg",
  },
  {
    label: "Proposta acquisto",
    value: "PROPOSTA_ACQUISTO",
    icon: "mediazione.svg",
  },
  {
    label: "Incarico non esclusiva",
    value: "INCARICO_NON_ESCLUSIVA",
    icon: "proposta-acquisto.svg",
  },
];

export const ContractStatusStrings = {
  CREATED: "Creato",
  SENT: "Inviato",
  SENT_VENDITORE: "Inviato a venditore",
  TO_CONFIRM: "Da confermare",
  CONFIRMED: "Confermato",
  DELETED: "Cancellato",
  SENT_PROPONENTE: "Inviato a proponente",
  TO_SEND_VENDITORE: "Da inviare a venditore",
};
export const ContractTypeStrings = {
  MANDATO_AFFITTO: "Mandato di affitto",
  MANDATO_VENDITA: "Mandato di vendita",
  PROPOSTA_ACQUISTO: "Proposta di acquisto",
  MEDIAZIONE: "Mediazione",
  INCARICO_ESCLUSIVA: "Incarico esclusiva",
  INCARICO_NON_ESCLUSIVA: "Incarico non esclusiva",
};
