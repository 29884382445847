import { useContext } from "react";
import { Link } from "react-router-dom";

import AuthContext from "../../store/auth-context";

export const Header = () => {
  const authCtx = useContext(AuthContext);
  return (
    <header className="py-6">
      <div className="container mx-auto flex justify-between">
        <Link to="/">
          <img className="w-36 lg:w-full" src="/images/logo.svg" alt="Logo" />
        </Link>

        {authCtx.isLoggedIn && (
          <div className="flex items-center gap-6">
            <button
              className="hover:text-primary-800 transition-all text-xs lg:text-base"
              onClick={() => authCtx.logout()}
            >
              Logout
            </button>
            <button className="hidden lg:block">
              <img src="/icons/user.svg" alt="Utente" />
            </button>
          </div>
        )}
      </div>
    </header>
  );
};
