import { useState } from "react";
import SignatureCanvas from "react-signature-canvas";

import Button from "../UI/Button.component";
import Modal from "../UI/Modal.component";

type SignatureModalProps = {
  onClose: (signature?: string) => void;
  signature?: string;
};
export const SignatureModal = ({ onClose, signature }: SignatureModalProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [sigCanvas, setSigCanvas] = useState<SignatureCanvas | null>();

  const confirmSignature = () => {
    setIsOpen(false);
    setTimeout(() => {
      const signature = sigCanvas?.toDataURL();

      onClose(signature);
    });
  };
  return (
    <Modal
      sizeClasses="w-11/12 rounded-3xl p-12 max-h-[95%] min-h-[15rem]"
      onClose={onClose}
      show={isOpen}
    >
      <div className="relative w-full">
        <SignatureCanvas
          ref={(ref) => {
            setSigCanvas(ref);
            if (signature) {
              sigCanvas?.fromDataURL(signature);
            }
          }}
          canvasProps={{
            className: "sigCanvas mb-12 w-full h-96 ",
          }}
        />
        <div className="absolute -bottom-6 flex gap-6 items-center justify-center w-full">
          <Button
            onClick={() => {
              confirmSignature();
            }}
            title="Conferma"
            colors="bg-success-500 hover:bg-success-600"
            className="w-14 h-14 !px-2 rounded-full text-center justify-center"
          >
            <img
              className="w-7 h-7 inline-block"
              src="/icons/check.svg"
              alt="Conferma"
            />
          </Button>
          <Button
            onClick={() => sigCanvas?.clear()}
            title="Cancella"
            colors="bg-secondary-700"
            className="w-14 h-14 !px-2 rounded-full"
          >
            <img
              className="w-7 h-7 inline-block"
              src="/icons/redo.svg"
              alt="Cancella"
            />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
