import { InputHTMLAttributes } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { mergeClasses } from "../../utils/classes.utils";

type InputProps<T extends FieldValues> = {
  className?: string;
  control: Control<T, string>;
  name: Path<T>;
  rules?: RegisterOptions<T, Path<T>>;
  errors?: FieldErrors<T>;
} & InputHTMLAttributes<HTMLElement>;
const Input = <T extends FieldValues>(props: InputProps<T>) => {
  const defaultValue = "";
  const { type = "text", className = "" } = props;
  const defaultClasses = "h-12 rounded-3xl px-3 text-secondary-700 w-full";
  const classes = [defaultClasses, className];
  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={defaultValue as any}
      rules={props.rules}
      render={({ field }) => {
        return (
          <>
            {props.placeholder && (
              <label className="text-base block mb-2">
                {props.placeholder}
              </label>
            )}

            <input
              {...field}
              {...props}
              type={type}
              onClick={props.onClick}
              className={mergeClasses(classes)}
            />

            {props.errors && props.errors[props.name] && (
              <small className="text-red-500 mt-3 block">{props.errors[props.name]?.message?.toString()}</small>
            )}
          </>
        );
      }}
    />
  );
};

export default Input;
