import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { RegisterForm } from '../../components/Auth/RegisterForm.component';
import Button from '../../components/UI/Button.component';
import Heading from '../../components/UI/Heading.component';
import Modal from '../../components/UI/Modal.component';
import { Spinner } from '../../components/UI/Spinner.component';
import { RegisterPayload, RegisterResponse } from '../../models/user.models';
import { fetchAPI } from '../../utils/api.utils';

const RegisterPage = () => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegisterPayload>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const register = async (data: RegisterPayload) => {
    return await fetchAPI<RegisterResponse>("/auth/register", {
      method: "POST",
      body: JSON.stringify(data),
    });
  };

  const onSubmit = async (data: RegisterPayload) => {
    setHasError(false);
    setIsLoading(true);
    try {
      const response = await register(data);
      setIsLoading(false);
      if (response.success) {
        setShowModal(true);
        setModalMessage(response.message);
      } else {
        setShowModal(true);
        setHasError(true);
        setModalMessage(response.message ?? "Si è verificato un problema");
      }
    } catch (error) {
      setShowModal(true);
      setIsLoading(false);
      setHasError(true);
      setModalMessage("Si è verificato un problema");
    }
  };

  return (
    <Spinner showSpinner={isLoading}>
      <Modal
        type={hasError ? "error" : "success"}
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        {modalMessage}

        {!hasError && (
          <Button
            onClick={() => navigate("/auth")}
            colors="bg-white text-success-500 border-white hover:bg-secondary-200"
          >
            Torna alla login
          </Button>
        )}
      </Modal>
      <div className="max-w-3xl mx-auto">
        <div className="bg-secondary-300 p-12 rounded-3xl">
          <Heading>Registrati</Heading>
          <RegisterForm
            errors={errors}
            watch={watch}
            handleSubmit={handleSubmit(onSubmit)}
            control={control}
          />
        </div>
      </div>
    </Spinner>
  );
};
export default RegisterPage;
