import { useForm } from "react-hook-form";

import { Contract, ContractDocumentForm } from "../../models/contract.models";
import Button from "../UI/Button.component";
import Heading from "../UI/Heading.component";
import { InputFile } from "../UI/InputFile.component";

type ContractDocumentsProps = {
  contract: Contract;
  next: (documents: ContractDocumentForm) => void;
  prev: () => void;
};
export const ContractDocuments = ({
  next,
  prev,
  contract,
}: ContractDocumentsProps) => {
  const { control, handleSubmit } = useForm<ContractDocumentForm>();

  const onSubmit = async (data: ContractDocumentForm) => {
    console.log(data);
    next(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <Heading color="text-primary-500">Carica documenti</Heading>
        <p className="text-base">
          Per procedere devi caricare le foto della tua carta d'identità e del
          tuo codice fiscale. Procedi scattando le due foto cliccando sui tasti
          qui sotto.
        </p>
        <br />
        <small>
          <strong>
            N.B. Le foto devono essere ben visibili in modo tale che si possano
            comprendere facilmente tutti i dati.
          </strong>
        </small>
        <Heading className="mt-12" as="h4">
          Carica documento di identità
        </Heading>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <InputFile
            
            accept="image/*"
            placeholder="Clicca qui e scatta una foto del fronte del documento."
            control={control}
            name="documentoFronte"
          />

          <InputFile
            
            accept="image/*"
            placeholder="Clicca qui e scatta una foto del retro del documento."
            control={control}
            name="documentoRetro"
          />
        </div>

        <Heading className="mt-12" as="h4">
          Carica tessera sanitaria
        </Heading>

        <InputFile
          
          placeholder="Clicca qui e scatta una foto della tessera sanitaria."
          control={control}
          accept="image/*"
          name="tesseraFronte"
        />
        <hr className="my-12 border-secondary-400" />
        <div className="my-6 flex items-center">
          <Button>Procedi</Button>
          <Button
            onClick={prev}
            type="button"
            weight="font-normal"
            className="!capitalize"
            colors="bg-transparent text-secondary-700"
          >
            Torna indietro
          </Button>
        </div>
      </div>
    </form>
  );
};
