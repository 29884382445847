import React, { useCallback, useState } from "react";

import { User } from "../models/user.models";

const AuthContext = React.createContext({
  token: "",
  user: {} as User,
  isLoggedIn: false,
  login: (token: string, user: User | undefined) => {},
  logout: () => {},
});

const retrieveStoredAuth = () => {
  const storedToken = localStorage.getItem("token");
  const storedUser: User = JSON.parse(localStorage.getItem("user") ?? "null");
  return {
    token: storedToken,
    user: storedUser,
  };
};

export const AuthContextProvider = (props: any) => {
  const authData = retrieveStoredAuth();

  let initialToken;
  let initialUser: User | undefined;
  if (authData) {
    initialToken = authData.token;
    initialUser = authData.user;
  }

  const [token, setToken] = useState(initialToken);
  const [user, setUser] = useState(initialUser);

  const userIsLoggedIn = !!token;

  const logoutHandler = useCallback(() => {
    setToken(null);
    setUser(undefined);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  }, []);

  const loginHandler = (token: string, user: User | undefined) => {
    setToken(token);
    setUser(user);
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const contextValue = {
    token: token ?? "",
    isLoggedIn: userIsLoggedIn,
    user: user ?? ({} as User),
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
