import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { TemplateList } from "../../../components/Admin/TemplateList/TemplateList.component";
import Heading from "../../../components/UI/Heading.component";
import { Spinner } from "../../../components/UI/Spinner.component";
import { environment } from "../../../config";
import useHttp from "../../../hooks/useHttp";
import { contractTypes } from "../../../models/contract.models";
import { Template } from "../../../models/template.models";
import { Choice, useConfirm } from "../../../store/confirm-modal.context";
import { formatDateToString } from "../../../utils/dates.utils";

export const HomePage = () => {
  const confirm = useConfirm();
  const { isLoading, sendRequest: fetchTemplates } = useHttp<
    Template[],
    "templates"
  >();
  const { isLoading: isLoadingDelete, sendRequest: deleteTemplate } = useHttp<
    Template[],
    "templates"
  >();

  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    fetchTemplates(
      { url: `${environment.API_ENDPOINT}/templates` },
      (response) => {
        
        setTemplates(response.templates);
      }
    );
  }, [fetchTemplates]);

  const confirmDeleteTemplate = (template: Template) => {
    deleteTemplate(
      {
        url: `${environment.API_ENDPOINT}/template/${template.id}`,
        method: "DELETE",
      },
      (response) => {
        
        if (response.success) {
          setTemplates(templates.filter((tpl) => template.id !== tpl.id));
        }
      }
    );
  };
  const handleDeleteTemplate = async (templateToDelete: Template) => {
    const shouldDelete = await confirm({
      type: "error",
      text: `Stai eliminando il template ${
        templateToDelete?.name
      } creato in data ${formatDateToString(
        templateToDelete.createdAt.toString()
      )}`,
    });
    if (shouldDelete === Choice.YES) {
      confirmDeleteTemplate(templateToDelete);
    }
  };
  return (
    <div>
      <Heading>Benvenuto!</Heading>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {contractTypes.map((contractType) => (
          <Link
            key={contractType.label}
            to={"/admin/contract/" + contractType.value}
          >
            <div className="bg-secondary-300 text-center flex-col flex items-center justify-center h-[40vh] lg:h-[30vh] rounded-3xl hover:bg-secondary-400 transition-all cursor-pointer">
              <img
                className="mb-3"
                src={"/icons/" + contractType.icon}
                alt={contractType.label}
              />
              <Heading>{contractType.label}</Heading>
            </div>
          </Link>
        ))}
      </div>

      <Spinner showSpinner={isLoading || isLoadingDelete}>
        <TemplateList
          askForDelete={async (template) => {
            handleDeleteTemplate(template);
          }}
          templates={templates}
        />
      </Spinner>
    </div>
  );
};
