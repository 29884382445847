import { BaseSyntheticEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { CreateContractForm } from "../../../components/Admin/CreateContractForm/CreateContractForm.component";
import { SendContractModal } from "../../../components/Admin/SendContractModal/SendContractModal.component";
import Button from "../../../components/UI/Button.component";
import Heading from "../../../components/UI/Heading.component";
import Modal from "../../../components/UI/Modal.component";
import { Spinner } from "../../../components/UI/Spinner.component";
import { environment } from "../../../config";
import useHttp from "../../../hooks/useHttp";
import {
  Contract,
  CreateContractPayload,
} from "../../../models/contract.models";
import { Template } from "../../../models/template.models";
import { defaultEditorHTML } from "../../../utils/editor.utils";

type CreateContractPageProps = {
  clone?: boolean;
};
export const CreateContractPage = ({ clone }: CreateContractPageProps) => {
  const { idContract: idContractParam, contractType } = useParams();
  const isCreate = idContractParam === "create";
  const idContract = idContractParam && !isCreate ? idContractParam : null;

  const [redirectToHome, setRedirectToHome] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSendContract, setShowSendContract] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [templates, setTemplates] = useState<Template[]>([]);
  // const [alloggi, setAlloggi] = useState<string[]>([]);

  const { isLoading: isLoadingTemplate, sendRequest: fetchTemplates } = useHttp<
    Template[],
    "templates"
  >();
  // const { isLoading: isLoadingAlloggi, sendRequest: fetchAlloggi } = useHttp<
  //   string[],
  //   "apartments"
  // >();

  const { control, handleSubmit, setValue, getValues } =
    useForm<CreateContractPayload>({
      defaultValues: {
        html: defaultEditorHTML,
        ContractTypeId: contractType,
        hasDocuments: false,
      },
    });

  useEffect(() => {
    fetchTemplates(
      { url: `${environment.API_ENDPOINT}/templates` },
      (response) => {
        setTemplates(
          response.templates.filter(
            (template) => template.ContractTypeId === contractType
          )
        );
      }
    );
  }, [contractType, fetchTemplates]);
  // useEffect(() => {
  //   fetchAlloggi(
  //     {
  //       url: `${environment.API_ENDPOINT}/utils/getApartments/${contractType}`,
  //     },
  //     (response) => {
  //       setAlloggi(response.apartments);
  //     }
  //   );
  // }, [contractType, fetchAlloggi]);

  const navigate = useNavigate();

  useEffect(() => {
    if (redirectToHome) {
      navigate("/admin/home");
    }
  }, [redirectToHome, navigate]);

  const { isLoading, sendRequest: createContract } = useHttp<
    Contract,
    "contract"
  >();
  const { isLoading: isLoadingSend, sendRequest: sendContract } = useHttp<
    Contract,
    "contract"
  >();

  const {
    isLoading: isLoadingFetch,
    sendRequest: fetchContractDetail,
    error: errorCreate,
  } = useHttp<Contract, "contract">();

  useEffect(() => {
    if (!isCreate && !idContract) {
    }
    if (idContract) {
      fetchContractDetail(
        { url: `${environment.API_ENDPOINT}/contract/${idContract}` },
        ({ success, contract, message }) => {
          if (!success) {
            setShowModal(true);
            setHasError(true);
            setModalMessage(message ?? "Si è verificato un problema.");
          }

          // const contractSanitized = contract.html.replaceAll(
          //   'data-input-listener="true"',
          //   ""
          // );
          if (!clone) {
            setValue("id", contract.id);
            setValue("name", contract.name);
            setValue("hasDocuments", contract.hasDocuments);
            setValue("apartment", contract.apartment);
          }
          setValue("html", contract.html);
          setValue("ContractTypeId", contract.ContractTypeId);
        }
      );
    }
  }, [fetchContractDetail, idContract, isCreate, setValue, clone]);

  const onTemplateChange = (template: string) => {
    const templateSanitized = template.replaceAll(
      'data-input-listener="true"',
      ""
    );
    setValue("html", templateSanitized);
  };

  const onSendContract = (email: string) => {
    const iframeHTML =
      document.querySelector("iframe")?.contentWindow?.document;
    if (iframeHTML) {
      setShowSendContract(false);
      sendContract(
        {
          url: `${environment.API_ENDPOINT}/sendContract`,
          method: "POST",
          body: { ...getValues(), email },
        },
        ({ success, message, contract }) => {
          setShowModal(true);
          setHasError(!success);

          if (!success) {
            setModalMessage(message ?? "");
          } else {
            if (clone) {
              navigate(`/admin/contract/${contractType}/${contract.id}`);
            }
            setModalMessage(`Contratto inviato con successo`);
          }
        }
      );
    }
  };
  const saveContract = (data: CreateContractPayload) => {
    const iframeHTML =
      document.querySelector("iframe")?.contentWindow?.document;
    if (iframeHTML) {
      createContract(
        {
          url: `${environment.API_ENDPOINT}/contract`,
          method: "POST",
          body: { ...data },
        },
        ({ success, message, contract }) => {
          setShowModal(true);
          setHasError(!success);
          if (!success) {
            setModalMessage(message ?? "");
          } else {
            if (clone) {
              navigate(`/admin/contract/${contractType}/${contract.id}`);
            }
            setModalMessage(
              `Contract ${isCreate ? "creato" : "modificato"} con successo`
            );
          }
        }
      );
    }
  };
  const onSubmit = async (
    data: CreateContractPayload,
    event: BaseSyntheticEvent<object, any, any> | undefined
  ) => {
    let eventType;

    if (event) {
      eventType = (event.nativeEvent as any).submitter.name;
      if (eventType === "send") {
        setShowSendContract(true);
      } else {
        saveContract(data);
      }
    }
  };
  return (
    <div>
      <Spinner showSpinner={isLoading || isLoadingFetch || isLoadingSend}>
        <Heading>{isCreate ? "Crea" : "Modifica"} contratto</Heading>

        {showSendContract && (
          <SendContractModal
            sendContract={(email) => onSendContract(email)}
            onClose={() => setShowSendContract(false)}
          />
        )}
        <Modal
          type={hasError ? "error" : "success"}
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          {modalMessage}

          {!hasError && (
            <Button
              onClick={() => {
                setShowModal(false);
                setRedirectToHome(true);
              }}
              colors="bg-white text-success-500 border-white hover:bg-secondary-200"
            >
              Torna alla home
            </Button>
          )}
        </Modal>

        <CreateContractForm
          // alloggi={alloggi}
          templates={templates}
          isCreate={isCreate}
          control={control}
          onTemplateChange={(template) => onTemplateChange(template)}
          handleSubmit={handleSubmit(onSubmit)}
        />
      </Spinner>
    </div>
  );
};
