import { Header } from "./Header.component";

const Layout = (props: { children: any }) => {
  return (
    <div>
      <Header></Header>
      <main className="container mx-auto">{props.children}</main>
    </div>
  );
};

export default Layout;
