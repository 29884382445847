import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { CreateTemplateForm } from "../../../components/Admin/CreateTemplateForm/CreateTemplateForm.component";
import Button from "../../../components/UI/Button.component";
import Heading from "../../../components/UI/Heading.component";
import Modal from "../../../components/UI/Modal.component";
import { Spinner } from "../../../components/UI/Spinner.component";
import { environment } from "../../../config";
import useHttp from "../../../hooks/useHttp";
import { contractTypes } from "../../../models/contract.models";
import {
  CreateTemplatePayload,
  Template,
} from "../../../models/template.models";
import {
  defaultEditorHTML,
} from "../../../utils/editor.utils";

export const CreateTemplatePage = () => {
  const { idTemplate: idTemplateParam } = useParams();
  const isCreate = idTemplateParam === "create";
  const idTemplate = idTemplateParam && !isCreate ? idTemplateParam : null;

  const [redirectToHome, setRedirectToHome] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const { control, handleSubmit, setValue } = useForm<CreateTemplatePayload>({
    defaultValues: {
      html: defaultEditorHTML,
      ContractTypeId: contractTypes[0].value,
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (redirectToHome) {
      navigate("/admin/home");
    }
  }, [redirectToHome, navigate]);

  const { isLoading, sendRequest: createTemplate } = useHttp<
    Template,
    "template"
  >();

  const {
    isLoading: isLoadingFetch,
    sendRequest: fetchTemplateDetail,
    error: errorCreate,
  } = useHttp<Template, "template">();

  useEffect(() => {
    if (!isCreate && !idTemplate) {
    }
    if (idTemplate) {
      fetchTemplateDetail(
        { url: `${environment.API_ENDPOINT}/template/${idTemplate}` },
        ({ success, template, message }) => {
          if (!success) {
            setShowModal(true);
            setModalMessage(message ?? "Si è verificato un problema.");
          }
          setValue("id", template.id);
          setValue("html", template.html);
          setValue("name", template.name);
          setValue("ContractTypeId", template.ContractTypeId);
        }
      );
    }
  }, [fetchTemplateDetail, idTemplate, isCreate, setValue]);

  const onSubmit = async (data: CreateTemplatePayload) => {
    const iframeHTML =
      document.querySelector("iframe")?.contentWindow?.document;
    if (iframeHTML) {
      createTemplate(
        {
          url: `${environment.API_ENDPOINT}/template`,
          method: "POST",
          body: { ...data },
        },
        ({ success, message }) => {
          setShowModal(true);
          setHasError(!success);
          if (!success) {
            setModalMessage(message ?? "");
          } else {
            setModalMessage(
              `Template ${isCreate ? "creato" : "modificato"} con successo`
            );
          }
        }
      );
    }
  };
  return (
    <div>
      <Spinner showSpinner={isLoading || isLoadingFetch}>
        <Heading>{isCreate ? "Crea" : "Modifica"} template</Heading>

        <Modal
          type={hasError ? "error" : "success"}
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          {modalMessage}

          {!hasError && (
            <Button
              onClick={() => {
                setShowModal(false);
                setRedirectToHome(true);
              }}
              colors="bg-white text-success-500 border-white hover:bg-secondary-200"
            >
              Torna alla home
            </Button>
          )}
        </Modal>

        <CreateTemplateForm
          isCreate={isCreate}
          control={control}
          handleSubmit={handleSubmit(onSubmit)}
        />
      </Spinner>
    </div>
  );
};
