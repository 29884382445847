import { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../UI/Button.component";
import Heading from "../UI/Heading.component";
import { SignatureModal } from "./SignatureModal.component";

type ContractSignProps = {
  next: (sign: string) => void;
  prev: () => void;
};
export const ContractSign = ({ next, prev }: ContractSignProps) => {
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signature, setSignature] = useState<string>();

  const onConfirmSignature = (signature?: string) => {
    setShowSignatureModal(false);
    console.log(signature);
    if (signature || signature === "") {
      setSignature(signature);
    }
  };
  return (
    <div>
      {showSignatureModal && (
        <SignatureModal
          signature={signature}
          onClose={(signature) => onConfirmSignature(signature)}
        />
      )}
      <Heading color="text-primary-500">Firma e invia</Heading>
      <Heading as="h4">privacy policy / Term condition</Heading>

      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.{" "}
      </p>
      <hr className="my-12 border-secondary-400" />
      <Heading as="h4">Firma il documento</Heading>

      <div
        onClick={() => setShowSignatureModal(true)}
        className={
          "lg:rounded-full rounded-2xl border border-dashed flex items-center justify-center h-72 lg:h-40 relative z-20 p-6 text-center " +
          (signature ? "border-success-500" : "border-secondary-500")
        }
      >
        {!signature && <strong>Inserisci la tua firma</strong>}
        {signature && (
          <strong className="text-success-500">
            Firma caricata con successo!
          </strong>
        )}
      </div>
      <div className="my-6 flex gap-6 items-center">
        <Button
          disabled={!signature}
          onClick={() => next(signature ?? '')}
          className="flex items-center gap-3 lg:text-base text-xs mt-3 lg:mt-0"
        >
          <img className="hidden lg:block" src="/icons/send.svg" alt="Invia" />{" "}
          <span className="h-6 lg:leading-normal leading-3">Invia documento</span>
        </Button>
        <Button
          onClick={prev}
          weight="font-normal"
          className="!capitalize"
          colors="bg-transparent text-secondary-700"
        >
          Torna indietro
        </Button>
      </div>
    </div>
  );
};
