import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../store/auth-context";

export const RedirectRoute = () => {
  const authCtx = useContext(AuthContext);
  if (!authCtx.isLoggedIn) {
    return <Navigate to="/auth" />;
  }
  return authCtx.user.role === "ADMIN" ? (
    <Navigate to="/admin/home" />
  ) : (
    <Navigate to="/user/home" />
  );
};
