import { FormEventHandler } from "react";
import { Control } from "react-hook-form";
import { Link } from "react-router-dom";

import { LoginPayload } from "../../models/user.models";
import Button from "../UI/Button.component";
import Input from "../UI/Input.component";

type LoginFormProps = {
  control: Control<LoginPayload>;
  handleSubmit: FormEventHandler<HTMLFormElement>;
};
export const LoginForm = ({ control, handleSubmit }: LoginFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-3 mb-6">
        <div>
          <Input
            required
            control={control}
            placeholder="Email"
            name="email"
            type="email"
          />
        </div>
        <div>
          <Input
            required
            control={control}
            placeholder="Password"
            name="password"
            type="password"
          />
        </div>
      </div>
      <Button className="flex items-center justify-center mb-6">
        <img className="mr-3" src="/icons/sign-in.svg" alt="Accedi" /> Accedi
      </Button>

      {/* <p className="italic">
        Sei un nuovo utente?{" "}
        <Link to="/register">
          <strong className="hover:text-primary-800 transition-all">
            Registrati!
          </strong>
        </Link>
      </p> */}
    </form>
  );
};
