import { FormEventHandler } from "react";
import { Control } from "react-hook-form";

import { CreateContractPayload } from "../../../models/contract.models";
import { Template } from "../../../models/template.models";
import Button from "../../UI/Button.component";
import EditorInput from "../../UI/Editor.component";
import Input from "../../UI/Input.component";
import UISelect from "../../UI/Select.component";
import { ReactComponent as CreateIcon } from "../../../assets/icons/create.svg";

type CreateContractFormProps = {
  isCreate: boolean;
  control: Control<CreateContractPayload>;
  templates: Template[];
  alloggi?: string[];
  onTemplateChange: (template: string) => void;
  handleSubmit: FormEventHandler<HTMLFormElement>;
};
export const CreateContractForm = ({
  isCreate,
  control,
  templates,
  alloggi,
  onTemplateChange,
  handleSubmit,
}: CreateContractFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 lg:grid-cols-4 items-center lg:gap-6 mb-6">
        <div className="mb-6">
          <Input required control={control} name="name" placeholder="Nome" />
        </div>
        <div className="mb-6">
          <label className="block mb-2">Template</label>
          <UISelect
            control={control}
            name="template"
            onSelectChange={(ev) => onTemplateChange(ev)}
            items={templates.map((template) => ({
              value: template.html,
              label: template.name,
            }))}
          />
        </div>
        {/* <div className="mb-6">
          <label className="block mb-2">Appartamento</label>
          <UISelect
            control={control}
            name="apartment"
            items={alloggi.map((alloggio) => ({
              value: alloggio,
              label: alloggio,
            }))}
          />
        </div> */}
        <div className="flex items-center gap-6 ml-3">
          <Input
            className="h-6 w-auto"
            type="checkbox"
            id="hasDocuments"
            name="hasDocuments"
            control={control}
          />
          <label htmlFor="hasDocuments" className="block flex-1">
            Controllo documenti
          </label>
        </div>
      </div>
      <EditorInput control={control} name="html" />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-12">
        <Button
          className="mt-12 flex items-center gap-3 justify-center"
          colors="bg-white text-primary-500 border-2 border-primary-500 hover:bg-secondary-200"
        >
          <CreateIcon /> <span className="h-6">Salva</span>
        </Button>
        <Button
          name="send"
          formAction="send"
          className="mb-6 lg:mb-0 lg:mt-12 flex items-center gap-3 justify-center"
        >
          <CreateIcon /> <span className="h-6">Salva e invia</span>
        </Button>
      </div>
    </form>
  );
};
