import { environment } from "../../../config";
import { Contract } from "../../../models/contract.models";

type ContractPhotosProps = {
  contract: Contract;
};
export const ContractPhotos = ({ contract }: ContractPhotosProps) => {
  const documents: { title: string; key: keyof Contract }[] = [
    { title: "Documento fronte", key: "documentoFronte" },
    { title: "Documento retro", key: "documentoRetro" },
    { title: "Tessera sanitaria", key: "tesseraFronte" },
  ];
  return (
    <>
      {contract.ContractTypeId === "PROPOSTA_ACQUISTO" && (
        <span className="font-bold text-lg mb-3 block">Proponente</span>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-6">
        {contract.ContractTypeId === "PROPOSTA_ACQUISTO" &&
          documents.map((document) => {
            const keyFormatted = (document.key +
              "Proponente") as keyof Contract;
            return (
              <div key={document.key} className="flex flex-col gap-2 lg:gap-6">
                <span>{document.title}</span>
                {contract[keyFormatted] && environment.IMAGE_ENDPOINT && (
                  <img
                    className="max-w-md"
                    alt={document.title}
                    src={environment.IMAGE_ENDPOINT + contract[keyFormatted]}
                  />
                )}
              </div>
            );
          })}
      </div>
      {contract.ContractTypeId === "PROPOSTA_ACQUISTO" && (
        <span className="font-bold text-lg my-3 block">Venditore</span>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-6">
        {documents.map((document) => (
          <div key={document.key} className="flex flex-col gap-2 lg:gap-6">
            <span>{document.title}</span>
            {contract[document.key] && environment.IMAGE_ENDPOINT && (
              <img
                alt={document.title}
                src={environment.IMAGE_ENDPOINT + contract[document.key]}
                className="max-w-md"
              />
            )}
          </div>
        ))}
      </div>
    </>
  );
};
