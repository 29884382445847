import { useEffect, useRef, useState } from "react";
import { redirect, useNavigate, useParams } from "react-router-dom";

import Button from "../../../components/UI/Button.component";
import Modal from "../../../components/UI/Modal.component";
import { Spinner } from "../../../components/UI/Spinner.component";
import { Stepper } from "../../../components/User/Stepper.component";
import { environment } from "../../../config";
import useHttp from "../../../hooks/useHttp";
import {
  Contract,
  ContractDocumentForm,
} from "../../../models/contract.models";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { ContractSummary } from "../../../components/User/ContractSummary.component";
import { ContractDocuments } from "../../../components/User/ContractDocuments.component";
import { ContractSign } from "../../../components/User/ContractSign.component";
import { Choice, useConfirm } from "../../../store/confirm-modal.context";

export const ConfirmContractPage = () => {
  const { idContract: idContractParam } = useParams();
  const idContract = idContractParam ? idContractParam : null;
  const [contract, setContract] = useState<Contract>();
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { isLoading: isLoadingFetch, sendRequest: fetchContractDetail } =
    useHttp<Contract, "contract">();

  const [documents, setDocuments] = useState<ContractDocumentForm>();

  const [currentStep, setCurrentStep] = useState(0);

  const [redirectToHome, setRedirectToHome] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectToHome) {
      navigate("/admin/home");
    }
  }, [redirectToHome, navigate]);

  const { isLoading: isLoadingSign, sendRequest: signContract } = useHttp<
    Contract,
    "contract"
  >();

  const swiperRef = useRef<SwiperRef>(null);

  const confirm = useConfirm();
  const onSubmitDocuments = (documents: ContractDocumentForm) => {
    setDocuments(documents);
    swiperRef.current?.swiper.slideNext();
  };
  const onSubmitSignature = async (signature: string) => {
    const shouldSendContract = await confirm({
      text: `Sei sicuro di voler inviare il documento firmato ad Adriatica Immobiliare?`,
      confirmBtnCls: "bg-primary-500 hover:bg-primary-600 text-white",
      denyBtnCls: "bg-transparent text-current hover:bg-slate-50 capitalize",
      noText: "Non ancora",
    });
    if (shouldSendContract === Choice.YES) {
      confirmSendContract(signature);
    }
  };

  const confirmSendContract = (signature: string) => {
    let formData = new FormData();
    formData.set("documentoFronte", documents?.documentoFronte as Blob);
    formData.set("documentoRetro", documents?.documentoRetro as Blob);
    formData.set("tesseraFronte", documents?.tesseraFronte as Blob);
    formData.set("signature", signature as string);
    signContract(
      {
        url: `${environment.API_ENDPOINT}/signContract/${idContract}`,
        method: "POST",
        body: formData,
        shouldNotStringify: true,
        headers: {} as unknown as Headers,
      },
      ({ success, message }) => {
        setShowModal(true);
        setHasError(!success);
        if (!success) {
          setModalMessage(message ?? "");
        } else {
          setModalMessage(`Contratto firmato con successo`);
        }
      }
    );
  };
  useEffect(() => {
    if (idContract) {
      fetchContractDetail(
        { url: `${environment.API_ENDPOINT}/user/contract/${idContract}` },
        ({ success, contract, message }) => {
          if (!success) {
            setShowModal(true);
            setHasError(true);
            setModalMessage(message ?? "Si è verificato un problema.");
          } else {
            setContract(contract);
          }
        }
      );
    }
  }, [fetchContractDetail, idContract]);
  return (
    <>
      <Spinner showSpinner={isLoadingFetch || isLoadingSign}> </Spinner>

      <Modal
        type={hasError ? "error" : "success"}
        show={showModal}
        onClose={() => {
          setShowModal(false);
          if (!hasError) {
            setRedirectToHome(true);
          }
        }}
      >
        {modalMessage}

        {!hasError && (
          <Button
            onClick={() => {
              setShowModal(false);
              setRedirectToHome(true);
            }}
            colors="bg-white text-success-500 border-white hover:bg-secondary-200"
          >
            Torna alla home
          </Button>
        )}
      </Modal>
      {contract && (
        <div>
          <Stepper hasDocuments={contract.hasDocuments} step={currentStep} />
          <Swiper
            ref={swiperRef}
            spaceBetween={50}
            autoHeight={true}
            allowTouchMove={false}
            onSlideChange={(swiper) => {
              setCurrentStep(swiper.realIndex);
            }}
          >
            <SwiperSlide>
              <ContractSummary
                prev={() => navigate("/user/home")}
                next={() => swiperRef.current?.swiper.slideNext()}
                pdf={contract.contractPdf}
              />
            </SwiperSlide>
            {contract.hasDocuments && (
              <SwiperSlide>
                <ContractDocuments
                  contract={contract}
                  prev={() => swiperRef.current?.swiper.slidePrev()}
                  next={(documents) => onSubmitDocuments(documents)}
                />
              </SwiperSlide>
            )}

            <SwiperSlide>
              <ContractSign
                prev={() => swiperRef.current?.swiper.slidePrev()}
                next={(signature) => onSubmitSignature(signature)}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      )}
    </>
  );
};
