import Button from "./Button.component";
import Modal, { ModalProps } from "./Modal.component";

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type ConfirmModalProps = {
  text: string;
  yesText?: string;
  noText?: string;
  confirmBtnCls?: string;
  denyBtnCls?: string;
  onClose: () => void;
  onConfirm: () => void;
} & AtLeast<ModalProps, "show" | "onClose">;
export const ConfirmModal = (props: ConfirmModalProps) => {
  const {
    confirmBtnCls = "bg-white text-red-500 border-white hover:bg-red-200",
    denyBtnCls = "hover:text-red-200 transition-all bg-transparent",
  } = props;
  return (
    <Modal {...props} show={props.show} onClose={props.onClose}>
      {props.text}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-6 items-center lg:mt-6 w-full">
        <Button colors={confirmBtnCls} onClick={props.onConfirm}>
          {props.yesText ?? "Procedi"}
        </Button>
        <Button className={denyBtnCls} onClick={props.onClose}>
          {props.noText ?? "Torna indietro"}
        </Button>
      </div>
    </Modal>
  );
};
