import { HTMLAttributes } from "react";

import { mergeClasses } from "../../utils/classes.utils";

export type FontWeight =
  | "font-thin"
  | "font-extralight"
  | "font-light"
  | "font-normal"
  | "font-medium"
  | "font-semibold"
  | "font-bold"
  | "font-extrabold"
  | "font-black";
export type HeadingElements = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div";
export type FontSizes =
  | "text-xs"
  | "text-sm"
  | "text-base"
  | "text-lg"
  | "text-xl"
  | "text-2xl"
  | "text-3xl"
  | "text-4xl"
  | "text-5xl"
  | "text-6xl"
  | "text-7xl"
  | "text-base";

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  /** The size variant */
  size?: FontSizes | string;
  as?: HeadingElements;
  color?: string;
  weight?: FontWeight;
  children: React.ReactNode;
  uppercase?: boolean;
  margin?: string;
}

const mapElementToSize = (element: HeadingElements): string => {
  switch (element) {
    case "h6":
      return "text-sm";
    case "h5":
      return "text-base";
    case "h4":
      return "text-lg";
    case "h3":
      return "lg:text-2xl text-xl";
    case "h2":
      return "lg:text-4xl text-lg";
    case "h1":
      return "lg:text-2xl md:text-xl text-lg";
    default:
      return "text-base";
  }
};

const Heading = (props: HeadingProps, ref: React.Ref<HTMLHeadingElement>) => {
  const {
    as = "h1",
    size = mapElementToSize(as),
    children,
    color = "",
    uppercase = true,
    className = "",
    margin = "mb-6",
    weight = "font-black",
  } = props;
  const HeadingTag = as as keyof JSX.IntrinsicElements;
  const classes = [
    size,
    color,
    uppercase ? "uppercase" : "",
    weight,
    margin,
    className,
  ];
  const classesFormatted = mergeClasses(classes);
  return <HeadingTag className={classesFormatted}>{children}</HeadingTag>;
};

export default Heading;
export type { HeadingProps };
