import { environment } from "../../config";
import Button from "../UI/Button.component";

import Heading from "../UI/Heading.component";

type ContractSummaryProps = {
  pdf: string;

  next: () => void;
  prev: () => void;
};
export const ContractSummary = ({ pdf, next, prev }: ContractSummaryProps) => {
  return (
    <div>
      <Heading color="text-primary-500">Riepilogo contratto</Heading>

      <iframe
        height={500}
        className="w-full bg-secondary-300 rounded-lg p-6"
        src={environment.IMAGE_ENDPOINT + pdf}
        title="Contratto"
      />

      <div className="my-6 flex items-center">
        <Button onClick={next}>Procedi</Button>
        <Button
          onClick={prev}
          weight="font-normal"
          className="!capitalize"
          colors="bg-transparent text-secondary-700"
        >
          Torna indietro
        </Button>
      </div>
    </div>
  );
};
