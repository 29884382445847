import { useEffect, useState } from "react";

import Heading from "../../../components/UI/Heading.component";
import { Spinner } from "../../../components/UI/Spinner.component";
import { UserContractList } from "../../../components/User/UserContractList.component";
import { environment } from "../../../config";
import useHttp from "../../../hooks/useHttp";
import { Contract } from "../../../models/contract.models";

export const UserHomePage = () => {
  const { isLoading, sendRequest: fetchContracts } = useHttp<
    Contract[],
    "contracts"
  >();
  const [contracts, setContracts] = useState<Contract[]>([]);

  useEffect(() => {
    fetchContracts(
      { url: `${environment.API_ENDPOINT}/contracts` },
      (response) => {
        setContracts(response.contracts);
      }
    );
  }, [fetchContracts]);
  return (
    <Spinner showSpinner={isLoading}>
      <div>
        <Heading as="h4">Storico contratti</Heading>

        <UserContractList contracts={contracts} />
      </div>
    </Spinner>
  );
};
