import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ContractDetail } from "../../../components/Admin/ContractDetail/ContractDetail.component";
import Button from "../../../components/UI/Button.component";
import Heading from "../../../components/UI/Heading.component";
import Modal from "../../../components/UI/Modal.component";
import { Spinner } from "../../../components/UI/Spinner.component";
import { environment } from "../../../config";
import useHttp from "../../../hooks/useHttp";
import { Contract } from "../../../models/contract.models";
import { Choice, useConfirm } from "../../../store/confirm-modal.context";
import { SendContractModal } from "../../../components/Admin/SendContractModal/SendContractModal.component";

export const DetailContractPage = () => {
  const { idContract: idContractParam } = useParams();
  const idContract = idContractParam;

  const [contract, setContract] = useState<Contract>();
  const [showModal, setShowModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showSendContract, setShowSendContract] = useState(false);
  const confirm = useConfirm();

  const { isLoading: isLoadingSend, sendRequest: sendContractVenditore } =
    useHttp<Contract, "contract">();

  const onSendContract = (email: string) => {
    setShowSendContract(false);
    sendContractVenditore(
      {
        url: `${environment.API_ENDPOINT}/sendContractVenditore`,
        method: "POST",
        body: { ...contract, emailVenditore: email },
      },
      ({ success, message }) => {
        setShowModal(true);
        setHasError(!success);
        if (!success) {
          setModalMessage(message ?? "");
        } else {
          setModalMessage(`Contratto inviato con successo`);
        }
      }
    );
  };
  const askForConvalida = async () => {
    const shouldConvalida = await confirm({
      type: "success",
      text: `Stai confermando il contratto ${contract?.name}. Procedere?`,
    });
    if (shouldConvalida === Choice.YES) {
      onConvalidaDocumento();
    }
  };
  const askForReinvia = async () => {
    const shouldConvalida = await confirm({
      type: "success",
      text: `Vuoi reinviare il contratto ${contract?.name} al cliente ${
        contract?.emailVenditore ? contract?.emailVenditore : contract?.email
      }. Procedere?`,
    });
    if (shouldConvalida === Choice.YES) {
      onReinviaContratto();
    }
  };

  const [redirectToHome, setRedirectToHome] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectToHome) {
      navigate("/admin/home");
    }
  }, [redirectToHome, navigate]);

  const { isLoading: isLoadingFetch, sendRequest: fetchContractDetail } =
    useHttp<Contract, "contract">();
  const { isLoading: isLoadingConvalida, sendRequest: convalidaDocumenti } =
    useHttp<Contract, "contract">();
  const { isLoading: isLoadingReinvia, sendRequest: reinviaContratto } =
    useHttp<Contract, "contract">();

  const onConvalidaDocumento = () => {
    convalidaDocumenti(
      {
        url: `${environment.API_ENDPOINT}/contract/${idContract}/convalida`,
        method: "POST",
      },
      ({ success, contract, message }) => {
        setShowModal(true);
        setHasError(!success);
        if (!success) {
          setModalMessage(message ?? "");
        } else {
          setModalMessage(`Contratto convalidato con successo`);
        }
      }
    );
  };
  const onReinviaContratto = () => {
    reinviaContratto(
      {
        url: `${environment.API_ENDPOINT}/contract/${idContract}/invia`,
        method: "POST",
      },
      ({ success, contract, message }) => {
        setShowModal(true);
        setHasError(!success);
        if (!success) {
          setModalMessage(message ?? "");
        } else {
          setModalMessage(`Contratto reinviato con successo`);
        }
      }
    );
  };
  useEffect(() => {
    if (idContract) {
      fetchContractDetail(
        { url: `${environment.API_ENDPOINT}/contract/${idContract}` },
        ({ success, contract, message }) => {
          setContract(contract);
        }
      );
    }
  }, [fetchContractDetail, idContract]);

  return (
    <div>
      <Spinner
        showSpinner={
          isLoadingConvalida ||
          isLoadingFetch ||
          isLoadingSend ||
          isLoadingReinvia
        }
      >
        <Heading>Contratto n. {idContract}</Heading>

        <Modal
          type={hasError ? "error" : "success"}
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          {modalMessage}

          {!hasError && (
            <Button
              onClick={() => {
                setShowModal(false);
                setRedirectToHome(true);
              }}
              colors="bg-white text-success-500 border-white hover:bg-secondary-200"
            >
              Torna alla home
            </Button>
          )}
        </Modal>

        {showSendContract && (
          <SendContractModal
            sendContract={(email) => onSendContract(email)}
            onClose={() => setShowSendContract(false)}
          />
        )}
        {contract && (
          <ContractDetail
            convalidaDocumento={() => askForConvalida()}
            contract={contract}
            reinviaContratto={() => askForReinvia()}
            inviaVenditore={() => setShowSendContract(true)}
          />
        )}
      </Spinner>
    </div>
  );
};
