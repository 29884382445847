import "./App.css";

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "./components/Layout/Layout.component";
import { OnlyAdminOutlet } from "./hoc/AdminRoute";
import { PrivateOutlet } from "./hoc/PrivateRoute";
import { OnlyUserOutlet } from "./hoc/UserRoute";
import { HomePage } from "./pages/admin/home/HomePage";
import AuthPage from "./pages/auth/AuthPage";
import RegisterPage from "./pages/auth/RegisterPage";
import { RedirectRoute } from "./hoc/RedirectRoute";
import { UserHomePage } from "./pages/user/home/UserHomePage";
import { CreateTemplatePage } from "./pages/admin/templates/CreateTemplatePage";
import { ContractListPage } from "./pages/admin/contracts/ContractListPage";
import { CreateContractPage } from "./pages/admin/contracts/CreateContractPage";
import { ConfirmContractPage } from "./pages/user/ConfirmContract/ConfirmContractPage";
import { DetailContractPage } from "./pages/admin/contracts/DetailContractPage";
import UserAuthPage from "./pages/auth/UserAuthPage";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/auth" element={<AuthPage />}></Route>
        <Route path="/register" element={<RegisterPage />}></Route>
        <Route path="/user-auth" element={<UserAuthPage />}></Route>

        <Route path="/" element={<PrivateOutlet />}>
          <Route path="/" element={<RedirectRoute />} />
          <Route path="/admin" element={<OnlyAdminOutlet />}>
            <Route path="/admin/*" element={<Navigate to="/admin/home" />} />
            <Route path="/admin/home" element={<HomePage />} />
            <Route
              path="/admin/template/:idTemplate"
              element={<CreateTemplatePage />}
            />
            <Route
              path="/admin/contract/:contractType"
              element={<ContractListPage />}
            />
            <Route
              path="/admin/contract/:contractType/:idContract"
              element={<CreateContractPage />}
            />
            <Route
              path="/admin/contract/:contractType/:idContract/clone"
              element={<CreateContractPage clone={true} />}
            />
            <Route
              path="/admin/contract/:contractType/:idContract/detail"
              element={<DetailContractPage />}
            />
          </Route>
          <Route path="/user" element={<OnlyUserOutlet />}>
            <Route path="/user/*" element={<Navigate to="/user/home" />} />
            <Route path="/user/home" element={<UserHomePage />} />
            <Route
              path="/user/home/:idContract"
              element={<ConfirmContractPage />}
            />
          </Route>
        </Route>
        <Route path="*" element={<RedirectRoute />} />
      </Routes>
    </Layout>
  );
}

export default App;
