import { environment } from "../config";

export const fetchAPI = async <T extends unknown>(
  path: string,
  init?: RequestInit | undefined
) => {
  const res = await fetch(`${environment.API_ENDPOINT}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
    ...init,
  });
  try {
    return (await res.json()) as T;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
