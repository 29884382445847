import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import AuthContext from "../store/auth-context";

export const OnlyUserOutlet = () => {
  const authCtx = useContext(AuthContext);
  return authCtx.isLoggedIn && authCtx.user.role !== "ADMIN" ? (
    <Outlet />
  ) : (
    <Navigate to="/admin/home" />
  );
};
