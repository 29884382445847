import { MouseEvent, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { mergeClasses } from "../../utils/classes.utils";

export type ModalProps = {
  children: React.ReactNode;
  sizeClasses?: string;
  colorClasses?: string;
  onClose: () => void;
  align?: string;
  show: boolean;
  hasCloseBtn?: boolean;
  createPortal?: boolean;
  className?: string;
  closeOnOutsideClick?: boolean;

  type?: "error" | "success" | "standard";
  icon?: React.FunctionComponent;
};
const Modal = (props: ModalProps) => {
  const {
    children,
    onClose,
    show = false,
    align = "items-center",

    hasCloseBtn = true,
    createPortal = true,
    closeOnOutsideClick = false,
    className = "",
    type = "standard",
  } = props;

  const [isBrowser, setIsBrowser] = useState(false);

  const closeHandler = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    if (show && document && createPortal) {
      document.querySelector("body")?.classList.add("overflow-hidden");
    } else {
      document.querySelector("body")?.classList.remove("overflow-hidden");
    }
  }, [createPortal, show]);

  let colorClasses = props.colorClasses ?? "";
  if (!props.colorClasses) {
    switch (type) {
      case "error":
        colorClasses = "bg-red-500 text-white";
        break;
      case "success":
        colorClasses = "bg-success-500 text-white";
        break;

      default:
        colorClasses = "bg-white text-black";
        break;
    }
  }
  let icon;
  if (!props.icon) {
    switch (type) {
      case "error":
        icon = "/icons/error.svg";
        break;
      case "success":
        icon = "/icons/success.svg";
        break;

      default:
        break;
    }
  }
  const defaultClasses =
    "relative overflow-auto flex items-center justify-center flex-col gap-3 text-center";
  const sizeClasses =
    props.sizeClasses ??
    "lg:w-1/3 w-11/12 rounded-3xl p-12 max-h-[95%] min-h-[15rem]";

  const classes = [defaultClasses, sizeClasses, colorClasses, className];

  const handleCloseClick = (event: MouseEvent<HTMLButtonElement, any>) => {
    event.preventDefault();
    closeHandler();
  };
  const modalContent = show ? (
    <div
      onClick={() => {
        if (closeOnOutsideClick && show) {
          closeHandler();
        }
      }}
      className={
        "fixed z-20 flex justify-center bottom-0 h-screen w-screen bg-black bg-opacity-25 left-0 " +
        align
      }
    >
      <div className={mergeClasses(classes)}>
        {hasCloseBtn && (
          <div className="absolute top-6 right-6  ">
            <button
              title="Chiudi"
              onClick={(event) => handleCloseClick(event)}
              className="flex items-center justify-center font-extrabold rounded-full w-12 h-12 z-50 absolute right-0"
            >
              <CloseIcon className="hover:opacity-80 transition-all" />
            </button>
          </div>
        )}

        {icon && <img width={40} height={40} src={icon} alt={props.type} />}
        {children}
      </div>
    </div>
  ) : null;
  if (isBrowser && createPortal) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById("modal-root") as Element
    );
  } else {
    return modalContent;
  }
};

export default Modal;
