import { FormEventHandler } from "react";
import { Control, FieldErrors, UseFormWatch } from "react-hook-form";

import { RegisterPayload } from "../../models/user.models";
import Button from "../UI/Button.component";
import Input from "../UI/Input.component";

type RegisterFormProps = {
  control: Control<RegisterPayload>;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  watch: UseFormWatch<RegisterPayload>;
  errors: FieldErrors<RegisterPayload>;
};
export const RegisterForm = ({
  control,
  handleSubmit,
  watch,
  errors,
}: RegisterFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-3 mb-6">
        <div>
          <Input required control={control} placeholder="Nome" name="name" />
        </div>
        <div>
          <Input
            required
            control={control}
            placeholder="Cognome"
            name="surname"
          />
        </div>
        <div>
          <Input
            required
            control={control}
            placeholder="Email"
            name="email"
            type="email"
          />
        </div>
        <div>
          <Input
            required
            control={control}
            placeholder="Password"
            name="password"
            type="password"
          />
        </div>
        <div>
          <Input
            required
            control={control}
            placeholder="Conferma password"
            name="confirmPassword"
            type="password"
            errors={errors}
            rules={{
              validate: (val: string) => {
                if (watch("password") !== val) {
                  return "Le password non coincidono";
                }
              },
            }}
          />
        </div>
      </div>
      <Button className="flex items-center justify-center mb-6">
        <img className="mr-3" src="/icons/sign-in.svg" alt="Registrati" />{" "}
        Registrati
      </Button>
    </form>
  );
};
