export const formatDateToString = (
  date: string | number,
  options?: Intl.DateTimeFormatOptions
) => {
  if (!options) {
    options = {
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      year: "numeric",
      minute: "2-digit",
    };
  }
  return new Date(date).toLocaleDateString("it-IT", options);
};
