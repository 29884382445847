import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import AuthContext from "../store/auth-context";

export const OnlyAdminOutlet = () => {
  const authCtx = useContext(AuthContext);
  return authCtx.isLoggedIn && authCtx.user.role === "ADMIN" ? (
    <Outlet />
  ) : (
    <Navigate to="/user/home" />
  );
};
