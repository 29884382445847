import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import {
  ConfirmModal,
  ConfirmModalProps,
} from "../components/UI/ConfirmModal.component";

const ConfirmDialog = createContext<any>(null);

export enum Choice {
  "YES" = "YES",
  "NO" = "NO",
}
export function ConfirmDialogProvider<T>({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, setState] = useState<Partial<ConfirmModalProps>>({
    show: false,
    text: "",
  });
  const fn = useRef<(choice: Choice) => void>();
  const confirm = useCallback(
    (data: T) => {
      return new Promise((resolve) => {
        setState({ ...data, show: true });
        fn.current = (choice: Choice) => {
          resolve(choice);
          setState({ show: false });
        };
      });
    },
    [setState]
  );

  return (
    <ConfirmDialog.Provider value={confirm}>
      {children}
      <ConfirmModal
        {...state}
        show={!!state.show}
        text={state.text ?? "Sei sicuro?"}
        onClose={() => fn && fn.current && fn.current(Choice.NO)}
        onConfirm={() => fn && fn.current && fn.current(Choice.YES)}
      />
    </ConfirmDialog.Provider>
  );
}

export const useConfirm = () => {
  return useContext(ConfirmDialog);
};
