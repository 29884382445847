import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import AuthContext from "../store/auth-context";

export const PrivateOutlet = () => {
  const authCtx = useContext(AuthContext);
  if (!authCtx.isLoggedIn) {
    return <Navigate to="/auth" />;
  }
  return <Outlet />;
};
