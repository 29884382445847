import { ButtonHTMLAttributes, MouseEventHandler } from "react";

import { mergeClasses } from "../../utils/classes.utils";

type ButtonProps = {
  className?: string;
  colors?: string;
  weight?: string;
  children: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "submit" | "button" | "reset" | undefined;
} & ButtonHTMLAttributes<HTMLElement>;

const Button = (props: ButtonProps) => {
  const {
    className = "",
    colors = "bg-primary-500 hover:bg-primary-600 focus:bg-primary-700 text-white",
    type = "submit",
    weight = "font-extrabold",
    onClick = () => {},
  } = props;
  const defaultClasses =
    "transition-all uppercase rounded-3xl px-6 lg:px-12 py-3 disabled:bg-secondary-500 disabled:cursor-not-allowed";
  const classes = [colors, weight, defaultClasses, className];
  return (
    <button
      {...props}
      type={type}
      className={mergeClasses(classes)}
      onClick={onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
