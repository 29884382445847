import { Link } from "react-router-dom";
import { Template } from "../../../models/template.models";
import { formatDateToString } from "../../../utils/dates.utils";
import Button from "../../UI/Button.component";
import Heading from "../../UI/Heading.component";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ContractTypeStrings } from "../../../models/contract.models";

type TemplateListProps = {
  templates: Template[];
  askForDelete: (template: Template) => void;
};
export const TemplateList = ({
  templates,
  askForDelete,
}: TemplateListProps) => {
  return (
    <>
      <div className="mt-12 lg:flex justify-between items-center mb-6 gap-3">
        <Heading margin="m-0" size="h4">
          Template
        </Heading>

        <Link to="/admin/template/create">
          <Button
            className="flex items-center justify-center lg:text-base text-xs mt-3 lg:mt-0"
            colors="bg-white text-primary-500 border-2 border-primary-500 hover:bg-secondary-200"
          >
            <PlusIcon className="mr-3 hidden lg:block" /> Aggiungi template
          </Button>
        </Link>
      </div>

      <div className="overflow-auto">
        <table className="table w-full">
          <tbody>
            {templates.map((template) => (
              <tr key={template.id} className="odd:bg-secondary-200 w-full">
                <td>
                  <strong>{template.name}</strong>
                </td>
                <td>
                  <strong>
                    {ContractTypeStrings[template.ContractTypeId]}
                  </strong>
                </td>
                <td>
                  File modificato il giorno{" "}
                  {formatDateToString(template.updatedAt.toString())}
                </td>
                <td>
                  <div className="flex gap-6 items-center">
                    <Link to={"/admin/template/" + template.id}>
                      <button
                        title="Modifica"
                        className="bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center hover:bg-blue-900 transition-all"
                      >
                        <img src="/icons/edit.svg" alt="Modifica" />
                      </button>
                    </Link>

                    <button
                      onClick={() => askForDelete(template)}
                      title="Elimina"
                      className="bg-red-500 w-10 h-10 rounded-full flex justify-center items-center hover:bg-red-900 transition-all"
                    >
                      <img src="/icons/trash.svg" alt="Elimina" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
