import { useState } from "react";
import { environment } from "../../../config";
import { Contract } from "../../../models/contract.models";
import Button from "../../UI/Button.component";
import Modal from "../../UI/Modal.component";
import { ContractPhotos } from "../ContractPhotos/ContractPhotos.component";

type ContractDetailProps = {
  contract: Contract;
  convalidaDocumento: () => void;
  reinviaContratto: () => void;
  inviaVenditore: () => void;
};
export const ContractDetail = ({
  contract,
  convalidaDocumento,
  reinviaContratto,
  inviaVenditore,
}: ContractDetailProps) => {
  const [showDocuments, setShowDocuments] = useState(false);
  return (
    <div>
      {contract.contractPdfSigned || contract.contractPdf ? (
        <iframe
          height={500}
          className="w-full bg-secondary-300 rounded-lg p-6"
          src={
            environment.IMAGE_ENDPOINT +
            (contract.contractPdfSigned ?? contract.contractPdf)
          }
          title="Contratto"
        />
      ) : (
        "Si è verificato un problema."
      )}

      <div className="my-6 flex items-center gap-6">
        <span>Visualizza documenti</span>
        <Button onClick={() => setShowDocuments(true)}>Documenti</Button>
      </div>

      {contract.status === "TO_CONFIRM" && (
        <>
          <hr className="my-6" />
          <Button className="mb-6" onClick={convalidaDocumento}>
            Convalida contratto
          </Button>
        </>
      )}
      {(contract.status === "SENT_PROPONENTE" ||
        contract.status === "SENT") && (
        <>
          <hr className="my-6" />
          <Button className="mb-6" onClick={reinviaContratto}>
            Reinvia contratto
          </Button>
        </>
      )}
      {contract.status === "TO_SEND_VENDITORE" &&
        contract.ContractTypeId === "PROPOSTA_ACQUISTO" && (
          <>
            <hr className="my-6" />
            <Button className="mb-6" onClick={inviaVenditore}>
              Invia a venditore
            </Button>
          </>
        )}

      <Modal
        show={showDocuments}
        onClose={() => setShowDocuments(false)}
        sizeClasses="w-11/12 rounded-3xl p-12 max-h-[95%] min-h-[15rem] !block"
      >
        <ContractPhotos contract={contract} />
      </Modal>
    </div>
  );
};
