import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { mergeClasses } from "../../utils/classes.utils";

type SelectProps<T extends FieldValues> = {
  control: Control<T, string>;
  name: Path<T>;
  fromModal?: boolean;
  required?: boolean;
  disabled?: boolean;
  onSelectChange?: (event: string) => void;
  items: { value: string; label: string }[];
} & Partial<HTMLSelectElement>;
const UISelect = <T extends FieldValues>(props: SelectProps<T>) => {
  const { className = "", required = false, disabled = false } = props;
  const defaultClassName = "relative h-12 rounded-3xl w-full";
  const classes = [defaultClassName, className];
  const defaultValue = "";
  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={defaultValue as any}
      render={({ field }) => {
        return (
          <select
            {...field}
            required={required}
            disabled={disabled}
            className={mergeClasses(classes)}
            onChange={(event) => {
              field.onChange(event);
              if (props.onSelectChange) {
                props.onSelectChange(event.target.value);
              }
            }}
          >
            {<option></option>}
            {props.items.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        );
      }}
    />
  );
};
export default UISelect;
