import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { LoginForm } from "../../components/Auth/LoginForm.component";
import Heading from "../../components/UI/Heading.component";
import Modal from "../../components/UI/Modal.component";
import { Spinner } from "../../components/UI/Spinner.component";
import { LoginPayload, LoginResponse } from "../../models/user.models";
import AuthContext from "../../store/auth-context";
import { fetchAPI } from "../../utils/api.utils";

const AuthPage = () => {
  const { control, handleSubmit } = useForm<LoginPayload>();
  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const login = async (data: LoginPayload) => {
    return await fetchAPI<LoginResponse>("/auth/login", {
      method: "POST",
      body: JSON.stringify(data),
    });
  };

  const onSubmit = async (data: LoginPayload) => {
    setIsLoading(true);
    try {
      const response = await login(data);
      setIsLoading(false);
      if (response.token) {
        authCtx.login(response.token, response.user);
        navigate("/home");
      } else {
        setHasError(true);
        setErrorMessage(response.message ?? "Si è verificato un problema");
      }
    } catch (error) {
      setIsLoading(false);
      setHasError(true);
      setErrorMessage("Si è verificato un problema");
    }
  };
  return (
    <Spinner showSpinner={isLoading}>
      <Modal type="error" show={hasError} onClose={() => setHasError(false)}>
        {errorMessage}
      </Modal>
      <div className="max-w-3xl mx-auto">
        <div className="bg-secondary-300 p-12 rounded-3xl">
          <Heading>Login</Heading>

          <LoginForm handleSubmit={handleSubmit(onSubmit)} control={control} />
        </div>
      </div>
    </Spinner>
  );
};
export default AuthPage;
