import { mergeClasses } from "../../utils/classes.utils";
type StepperProps = {
  step: number;
  hasDocuments: boolean;
};
export const Stepper = ({ step, hasDocuments }: StepperProps) => {
  const bookingStepItems = hasDocuments ? [1, 2, 3] : [1, 2];
  const getParentClasses = (
    isLast: boolean,
    isActive: boolean,
    isCompleted: boolean
  ) => {
    const afterItem = !isLast
      ? "after:absolute after:w-full after:h-[5px] after:top-1/2 after:-right-10"
      : "";
    const afterItemBg = isActive
      ? "after:bg-secondary-700"
      : isCompleted
      ? "after:bg-success-500"
      : "after:bg-secondary-400";

    const flexItem = !isLast ? "flex-auto" : "flex-0";
    const parentClasses = ["relative", afterItem, afterItemBg, flexItem];
    return parentClasses;
  };
  const getElementClasses = (isActive: boolean, isCompleted: boolean) => {
    const bgElement = isActive
      ? "bg-secondary-700"
      : isCompleted
      ? "bg-success-500"
      : "bg-secondary-400";
    const elementDefaultClasses =
      "w-4 h-4 text-lg font-black flex items-center justify-center rounded-full text-white p-6 flex flex-col justify-center";
    return [elementDefaultClasses, bgElement];
  };
  return (
    <div className="py-12 max-w-[700px] mx-auto">
      <div className="lg:px-24 px-0 sm:px-6">
        <div className="flex justify-between">
          {bookingStepItems.map((key, index) => {
            const isLast = index === bookingStepItems.length - 1;
            const isActive = step === index;
            const isCompleted = step > index;
            const parentClasses = getParentClasses(
              isLast,
              isActive,
              isCompleted
            );
            const elementClasses = getElementClasses(isActive, isCompleted);

            const textDefaultClasses =
              "absolute left-0 lg:left-auto -bottom-6 text-[10px] leading-normal text-center whitespace-nowrap hidden lg:block";
            const textColorClasses = isCompleted
              ? "text-success-500"
              : isActive
              ? "text-secondary-700"
              : "text-secondary-400";
            const textClasses = [textDefaultClasses, textColorClasses];
            return (
              <div key={key} className={mergeClasses(parentClasses)}>
                <div className={mergeClasses(elementClasses)}>
                  {Intl.NumberFormat("it-IT", {
                    minimumIntegerDigits: 2,
                  }).format(index + 1)}
                  <span className={mergeClasses(textClasses)}>
                    {index === 0
                      ? "Riepilogo Contratto"
                      : index === 1
                      ? hasDocuments
                        ? "Carica Documenti"
                        : "Firma e Invia"
                      : "Firma e Invia"}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
