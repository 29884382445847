import { Link } from "react-router-dom";

import {
  Contract,
  ContractStatusStrings,
} from "../../../models/contract.models";
import { formatDateToString } from "../../../utils/dates.utils";

type ContractListProps = {
  contracts: Contract[];
  askForDelete: (contract: Contract) => void;
};
export const ContractList = ({
  contracts,
  askForDelete,
}: ContractListProps) => {
  return (
    <div className="overflow-auto">
      <table className="table w-full">
        <tbody>
          {contracts.map((contract) => {
            const isEditable =
              contract.status === "CREATED" ||
              contract.status === "SENT_PROPONENTE" ||
              (contract.status === "SENT" &&
                contract.ContractTypeId !== "PROPOSTA_ACQUISTO");

            let statusColor = "";
            switch (contract.status) {
              case "TO_CONFIRM":
                statusColor = "bg-orange-500";
                break;
              case "CONFIRMED":
                statusColor = "bg-success-500";
                break;
              case "DELETED":
                statusColor = "bg-red-500";
                break;
              case "SENT":
                statusColor = "bg-yellow-500";
                break;
              case "SENT_PROPONENTE":
                statusColor = "bg-yellow-500";
                break;
              case "TO_SEND_VENDITORE":
                statusColor = "bg-orange-500";
                break;
              case "CREATED":
                statusColor = "bg-blue-500";
                break;

              default:
                break;
            }
            return (
              <tr key={contract.id} className="odd:bg-secondary-200 w-full">
                <td>
                  <strong>{contract.name}</strong>
                </td>
                <td>
                  <strong>{contract.email ?? "-"}</strong>
                </td>
                <td>
                  <strong>{contract.apartment}</strong>
                </td>
                <td>
                  File modificato il giorno{" "}
                  {formatDateToString(contract.updatedAt.toString())}
                </td>
                <td>
                  <div className="flex gap-6 items-center">
                    <span className="w-32">
                      {
                        ContractStatusStrings[
                          contract.status === "SENT" &&
                          contract.ContractTypeId === "PROPOSTA_ACQUISTO"
                            ? "SENT_VENDITORE"
                            : contract.status
                        ]
                      }
                    </span>

                    <span
                      className={"rounded-full w-6 h-6 " + statusColor}
                    ></span>
                  </div>
                </td>
                <td>
                  <div className="flex gap-6 items-center">
                    {isEditable ? (
                      <Link to={"./" + contract.id} relative="path">
                        <button
                          title="Modifica"
                          className="bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center hover:bg-blue-900 transition-all"
                        >
                          <img src="/icons/edit.svg" alt="Modifica" />
                        </button>
                      </Link>
                    ) : (
                      <Link to={"./" + contract.id + "/detail"} relative="path">
                        <button
                          title="Dettaglio"
                          className="bg-black w-10 h-10 rounded-full flex justify-center items-center hover:bg-slate-800 transition-all"
                        >
                          <img src="/icons/search.svg" alt="Dettaglio" />
                        </button>
                      </Link>
                    )}

                    <Link to={"./" + contract.id + "/clone"} relative="path">
                      <button
                        title="Clona"
                        className="bg-yellow-400 w-10 h-10 rounded-full flex justify-center items-center hover:bg-yellow-500 transition-all"
                      >
                        <img src="/icons/clona.png" alt="Clona" />
                      </button>
                    </Link>
                    {contract.status !== "DELETED" && (
                      <button
                        onClick={() => askForDelete(contract)}
                        title="Elimina"
                        className="bg-red-500 w-10 h-10 rounded-full flex justify-center items-center hover:bg-red-900 transition-all"
                      >
                        <img src="/icons/trash.svg" alt="Elimina" />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
