import { Link } from "react-router-dom";
import { Contract, ContractTypeStrings } from "../../models/contract.models";
import { formatDateToString } from "../../utils/dates.utils";

type UserContractListProps = {
  contracts: Contract[];
};
export const UserContractList = ({ contracts }: UserContractListProps) => {
  return (
    <div className="overflow-auto">
      <table className="table w-full">
        <thead>
          <tr>
            <th>Codice / Nome</th>
            <th>Data di creazione</th>
            <th>Tipo Acquisto</th>
            <th>Stato</th>
            <th className="sticky lg:static -right-1 p-0 bg-gray-150">
              <div className="block lg:hidden h-20 shadow-2xl"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          {contracts.map((contract, index) => {
            let statusFormatted = "";
            let isEditable = false;
            let statusColor = "";
            switch (contract.status) {
              case "CONFIRMED":
                statusFormatted = "Confermato";
                statusColor = "bg-success-500";
                break;
              case "SENT":
                statusFormatted = "Da firmare";
                statusColor = "bg-blue-500";
                isEditable = true;
                break;
              case "SENT_PROPONENTE":
                statusFormatted = "Da firmare";
                statusColor = "bg-blue-500";
                isEditable = true;
                break;

              default:
                statusFormatted = "In attesa convalida";
                statusColor = "bg-yellow-500";
                break;
            }
            return (
              <tr key={contract.id} className="even:bg-secondary-200 w-full">
                <td>
                  <strong>
                    {contract.name}{" "}
                    {contract.apartment ? "/ " + contract.apartment : ""}
                  </strong>
                </td>
                <td>{formatDateToString(contract.createdAt.toString())}</td>
                <td>{ContractTypeStrings[contract.ContractTypeId]}</td>
                <td>
                  <div className="flex gap-6 items-center">
                    <span className="w-32">{statusFormatted}</span>

                    <span
                      className={"rounded-full w-6 h-6 " + statusColor}
                    ></span>
                  </div>
                </td>
                <td className="sticky lg:static w-12 -right-1 lg:bg-transparent bg-secondary-100 p-0">
                  <div className="shadow-2xl lg:shadow-none lg:h-auto h-20 lg:p-0 p-6">
                    {isEditable ? (
                      <Link to={"./" + contract.id} relative="path">
                        <button
                          title="Modifica"
                          className="bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center hover:bg-blue-900 transition-all"
                        >
                          <img src="/icons/edit.svg" alt="Modifica" />
                        </button>
                      </Link>
                    ) : (
                      <Link to={"./" + contract.id + "/detail"} relative="path">
                        <button
                          title="Dettaglio"
                          className="bg-black w-10 h-10 rounded-full flex justify-center items-center hover:bg-slate-800 transition-all"
                        >
                          <img src="/icons/search.svg" alt="Dettaglio" />
                        </button>
                      </Link>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
