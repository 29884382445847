import { FormEventHandler } from "react";
import { Control } from "react-hook-form";

import { contractTypes } from "../../../models/contract.models";
import { CreateTemplatePayload } from "../../../models/template.models";
import Button from "../../UI/Button.component";
import EditorInput from "../../UI/Editor.component";
import Input from "../../UI/Input.component";
import UISelect from "../../UI/Select.component";
import { ReactComponent as CreateIcon } from "../../../assets/icons/create.svg";
type CreateTemplateFormProps = {
  isCreate: boolean;
  control: Control<CreateTemplatePayload>;
  handleSubmit: FormEventHandler<HTMLFormElement>;
};
export const CreateTemplateForm = ({
  isCreate,
  control,
  handleSubmit,
}: CreateTemplateFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-center gap-6 mb-6">
        <div className="mb-6 w-60">
          <Input required control={control} name="name" placeholder="Nome" />
        </div>
        <div className="mb-6 w-60">
          <label className="block mb-2">Tipo di contratto</label>
          <UISelect
            required
            control={control}
            name="ContractTypeId"
            items={contractTypes}
          />
        </div>
      </div>
      <EditorInput control={control} name="html" />

      <Button className="mt-12 flex items-center gap-3">
        <CreateIcon />{" "}
        <span className="h-6">{isCreate ? "Crea" : "Modifica"} template</span>
      </Button>
    </form>
  );
};
