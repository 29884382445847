import { useCallback, useContext, useState } from "react";

import { HttpResponse } from "../models/http.models";
import AuthContext from "../store/auth-context";

const useHttp = <T extends object, K extends string>() => {
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const sendRequest = useCallback(
    async (
      requestConfig: {
        url: string;
        headers?: Headers;
        body?: any;
        method?: string;
        shouldNotStringify?: boolean;
      },
      applyData: (data: HttpResponse<T, K>) => void
    ) => {
      setIsLoading(true);
      setError(null);
      try {
        let headers: HeadersInit = requestConfig.headers
          ? { ...requestConfig.headers }
          : { "Content-Type": "application/json" };

        if (authCtx.token) {
          headers = { ...headers, Authorization: authCtx.token };
        }
        const response = await fetch(requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : "GET",
          headers,
          body: requestConfig.body
            ? requestConfig.shouldNotStringify
              ? requestConfig.body
              : JSON.stringify(requestConfig.body)
            : null,
        });

        const data = (await response.json()) as HttpResponse<T, K>;

        if (!response.ok) {
          throw new Error(data.message);
        }

        applyData(data);
      } catch (err: any) {
        const message = err.message || "Si è verificato un problema.";
        setIsLoading(false);
        setError(message);
        applyData({ success: false, message } as HttpResponse<T, K>);
      }
      setIsLoading(false);
    },
    [authCtx]
  );

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
