import { useCallback, useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";

import { ContractList } from "../../../components/Admin/ContractList/ContractList.component";
import Button from "../../../components/UI/Button.component";
import Heading from "../../../components/UI/Heading.component";
import { Spinner } from "../../../components/UI/Spinner.component";
import { environment } from "../../../config";
import useHttp from "../../../hooks/useHttp";
import { Contract, contractTypes } from "../../../models/contract.models";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { Choice, useConfirm } from "../../../store/confirm-modal.context";
import { formatDateToString } from "../../../utils/dates.utils";

export const ContractListPage = () => {
  const confirm = useConfirm();

  const { isLoading, sendRequest: fetchContracts } = useHttp<
    Contract[],
    "contracts"
  >();

  const { contractType: contractTypeParam } = useParams();
  const contractType = contractTypeParam ? contractTypeParam : null;
  const { isLoading: isLoadingDelete, sendRequest: deleteContract } = useHttp<
    Contract[],
    "contracts"
  >();

  const [contracts, setContracts] = useState<Contract[]>([]);

  const loadContracts = useCallback(() => {
    fetchContracts(
      { url: `${environment.API_ENDPOINT}/contracts/${contractType}` },
      (response) => {
        setContracts(response.contracts);
      }
    );
  }, [contractType, fetchContracts]);

  useEffect(() => {
    loadContracts();
  }, [loadContracts]);

  const confirmDeleteContract = (contract: Contract) => {
    deleteContract(
      {
        url: `${environment.API_ENDPOINT}/contract/${contract.id}`,
        method: "DELETE",
      },
      (response) => {
        if (response.success) {
          setContracts(contracts.filter((tpl) => contract.id !== tpl.id));
        }
      }
    );
  };
  if (!contractTypes.some((ctr) => ctr.value === contractType)) {
    return <Navigate to={"/admin/home"}></Navigate>;
  }
  const title = contractTypes.find(
    (type) => type.value === contractType
  )?.label;
  const handleDeleteContract = async (contractToDelete: Contract) => {
    const shouldDelete = await confirm({
      type: "error",
      text: `Stai eliminando il contratto ${
        contractToDelete?.name
      } creato in data ${formatDateToString(
        contractToDelete.createdAt.toString()
      )}`,
    });
    if (shouldDelete === Choice.YES) {
      confirmDeleteContract(contractToDelete);
    }
  };
  return (
    <div>
      <div className="lg:flex items-center justify-between mb-6">
        <Heading margin="m-0">{title}</Heading>

        <Link to="create">
          <Button className="flex items-center justify-center lg:text-base text-xs mt-3 lg:mt-0">
            <PlusIcon className="mr-3 hidden lg:block" /> Crea nuovo
          </Button>
        </Link>
      </div>
      <Spinner showSpinner={isLoading || isLoadingDelete}>
        <ContractList
          askForDelete={async (contract) => {
            handleDeleteContract(contract);
          }}
          contracts={contracts}
        />
      </Spinner>
    </div>
  );
};
