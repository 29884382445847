import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Modal from "../../components/UI/Modal.component";
import { Spinner } from "../../components/UI/Spinner.component";
import { environment } from "../../config";
import useHttp from "../../hooks/useHttp";
import { User } from "../../models/user.models";
import AuthContext from "../../store/auth-context";

const AuthPage = () => {
  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { isLoading, sendRequest: fetchUser } = useHttp<User, "user">();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");
    if (token) {
      authCtx.token = token;
      fetchUser(
        { url: `${environment.API_ENDPOINT}/auth/whoAmI` },
        (response) => {
          if (response.user) {
            authCtx.login(token, response.user);
            navigate("/home");
          } else {
            setHasError(true);
            setErrorMessage("Si è verificato un problema inaspettato.");
          }
        }
      );
    }
  }, [searchParams, fetchUser, authCtx, navigate]);

  return (
    <Spinner showSpinner={isLoading}>
      <Modal type="error" show={hasError} onClose={() => setHasError(false)}>
        {errorMessage}
      </Modal>
    </Spinner>
  );
};
export default AuthPage;
