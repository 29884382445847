import { Editor as EditorCmp } from "@tinymce/tinymce-react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { Editor } from "tinymce";

type EditorInputProps<T extends FieldValues> = {
  className?: string;
  control: Control<T, string>;
  name: Path<T>;
  rules?: RegisterOptions<T, Path<T>>;
  errors?: FieldErrors<T>;
};
const EditorInput = <T extends FieldValues>(props: EditorInputProps<T>) => {
  const listener = (editor: Editor, input: HTMLInputElement) =>
    editor.fire("updateInputValues", { inputEl: input });

  const removeListenerForInputChanges = (editor: Editor) => {
    editor
      .getDoc()
      .querySelectorAll("input")
      .forEach((input) => {
        input.setAttribute("data-input-listener", "false");
      });
  };
  const listenForInputChanges = (editor: Editor) => {
    editor
      .getDoc()
      .querySelectorAll("input")
      .forEach((input) => {
        if (
          !input.hasAttribute("data-input-listener") ||
          input.getAttribute("data-input-listener") === "false"
        ) {
          input.addEventListener("input", (event) => listener(editor, input));
          input.setAttribute("data-input-listener", "true");
        }
      });
  };
  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={"" as any}
      render={({ field: { onChange, value } }) => {
        const onButtonClick = (editor: Editor) => {
          editor.insertContent('<input value="" class="tiny-input" />');
        };
        const onButtonSmallClick = (editor: Editor) => {
          editor.insertContent(
            '<input value="" class="tiny-input" style="width: 75px;" />'
          );
        };
        const onFirmaClick = (editor: Editor) => {
          editor.insertContent("-----");
        };
        const onFirmaProponenteClick = (editor: Editor) => {
          editor.insertContent("----");
        };
        return (
          <EditorCmp
            apiKey="q0h6clpfvtb7hkaax1f9lc0kh4ptrnnogjdmo0f00qi0ejfp"
            cloudChannel={"6.7.3"}
            init={{
              content_style: `body {font-family: 'Montserrat'; line-height:2;}
              .tiny-input {
                border-width: 0;
                border-bottom: 1px solid #222;
                background: transparent;
                outline: 0px;
                font-weight: bold;
                font-size: 16px;
              } `,

              height: 500,
              convert_urls: false,
              plugins: "code anchor media table paste preview searchreplace",
              toolbar:
                "bold italic underline strikethrough | undo redo  styleselect fontfamily h2 p alignleft aligncenter alignright  fontsize|  code | preview searchreplace | inputBtn inputBtnSmall addFirma addFirmaProponente",
              setup: (editor) => {
                editor.ui.registry.addButton("inputBtn", {
                  icon: "line",

                  text: "Aggiungi campo",
                  onAction: (api) => onButtonClick(editor),
                });
                editor.ui.registry.addButton("inputBtnSmall", {
                  icon: "line",

                  text: "Aggiungi campo piccolo",
                  onAction: (api) => onButtonSmallClick(editor),
                });
                editor.ui.registry.addButton("addFirma", {
                  icon: "line",

                  text: "Aggiungi firma",
                  onAction: (api) => onFirmaClick(editor),
                });
                editor.ui.registry.addButton("addFirmaProponente", {
                  icon: "line",

                  text: "Aggiungi firma Proponente",
                  onAction: (api) => onFirmaProponenteClick(editor),
                });

                editor.on("init", () => {
                  removeListenerForInputChanges(editor);
                  listenForInputChanges(editor);
                });
                editor.on("undo", () => {
                  removeListenerForInputChanges(editor);
                  listenForInputChanges(editor);
                });
                editor.on("change", function () {
                  listenForInputChanges(editor);
                });
                editor.on(
                  "updateInputValues",
                  (args: { inputEl: HTMLInputElement }) => {
                    console.log("entro");
                    const inputEl = args.inputEl;
                    if (inputEl) {
                      if (inputEl.type === "checkbox") {
                        console.log(inputEl.checked);
                        editor.dom.setAttrib(
                          inputEl,
                          "checked",
                          inputEl.checked ? true : null
                        );
                      } else {
                        editor.dom.setAttrib(
                          inputEl,
                          "value",
                          inputEl.value ?? ""
                        );
                      }
                      editor.undoManager.add();
                    }
                  }
                );
                editor.on("keydown", function (e) {
                  const activeElement = editor.dom.doc.activeElement;
                  if (activeElement && activeElement?.tagName === "INPUT") {
                    if (e.keyCode === 8) {
                      e.preventDefault();
                      let val = (activeElement as HTMLInputElement).value;

                      val = val.substring(0, val.length - 1);

                      // update the value of the input
                      (activeElement as HTMLInputElement).value = val;
                      editor.fire("updateInputValues", {
                        inputEl: activeElement,
                      });
                    }
                  }
                });
              },
            }}
            value={value}
            onEditorChange={onChange}
          />
        );
      }}
    />
  );
};
export default EditorInput;
